<template>
  <label class="t1">把标签类型名称：</label>
  <label class="t2">{{dataValue.attributeModify.input.typeNameOld}}</label>
  <label class="t3">修改为：</label>
  <a-input class="t4" v-model:value="dataValue.attributeModify.input.typeNameNew"/>
  <a-button class="t5" type="primary" @click="allEvent.attributeModify.http">保存</a-button>
</template>

<script>
import {reactive} from "vue";
import {message} from "ant-design-vue";
import proxy from "@/utils/axios";

export default {
  name : "attribute_xx_modify",
  props : [ 'type_name_old' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      attributeModify : {
        input : {
          typeNameOld : props.type_name_old,
          typeNameNew : ""
        }
      }

    })

    let allEvent = {

      attributeModify : {

        http : () => {

          if (dataValue.attributeModify.input.typeNameNew === "") {
            message.error("请输入新的标题名称");
            return;
          }

          proxy.post('/admin/data.center/attribute.modify', dataValue.attributeModify.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              emit('goClose')
            }
          })

        }

      }

    }

    return { dataValue, allEvent }
  }
}
</script>

<style scoped>
.t1 { float: left; line-height: 33px; }
.t2 { float: left; line-height: 33px; color: saddlebrown; }
.t3 { float: left; line-height: 33px; margin-left: 10px; }
.t4 { float: left; width: 200px; }
.t5 { float: left; margin-left: 20px; }
.t6 { float: left; line-height: 33px; margin-left: 20px; color: coral; }
</style>