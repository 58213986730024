<template>
  <label class="t1">标签类型：</label>
  <label class="t1" v-if="dataValue.attributeAdd.haveOnlyTypeValue">{{dataValue.attributeAdd.input.typeName}}</label>
  <a-input class="t2" v-if="!dataValue.attributeAdd.haveOnlyTypeValue" v-model:value="dataValue.attributeAdd.input.typeName"/>
  <label class="t3">设置一个子项：</label>
  <a-input class="t4" v-model:value="dataValue.attributeAdd.input.typeValue"/>
  <a-button class="t5" type="primary" @click="allEvent.attributeAdd.http">保存</a-button>
</template>

<script>
import {reactive} from "vue";
import {message} from "ant-design-vue";
import proxy from "@/utils/axios";

export default {
  name : "attribute_xx_add",
  props : [ 'type_name' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      attributeAdd : {

        haveOnlyTypeValue : props.type_name !== "",

        input : {
          typeName : props.type_name,
          typeValue : ""
        }
      }

    })

    let allEvent = {

      attributeAdd : {

        http : () => {

          if (dataValue.attributeAdd.input.typeName === "") {
            message.error("请输入标签类型");
            return;
          }

          if (dataValue.attributeAdd.input.typeValue === "") {
            message.error("请输入子项内容");
            return;
          }

          proxy.post('/admin/data.center/attribute.add', dataValue.attributeAdd.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              emit('goClose')
            }
          })

        }

      }

    }

    return { dataValue, allEvent }
  }
}
</script>

<style scoped>
.t1 { float: left; line-height: 33px; }
.t2 { float: left; width: 200px; }
.t3 { float: left; line-height: 33px; margin-left: 30px; }
.t4 { float: left; width: 200px; }
.t5 { float: left; margin-left: 20px; }
.t6 { float: left; line-height: 33px; margin-left: 20px; color: coral; }
</style>