<template>
  <div class="addTypeName">
    <a-button @click="allEvent.attributeAdd.open('')">添加一个标签类型</a-button>
  </div>
  <template v-for="attributeType in dataValue.attributeList.output.list">
    <div class="attrDiv">
      <div class="t">
        <label class="add"><plus-square-outlined @click="allEvent.attributeAdd.open(attributeType['typeName'])" /></label>
        <label class="tInfo" @click="allEvent.attributeModify.open(attributeType['typeName'])">{{ attributeType['typeName'] }}</label>
        <a-popconfirm title="请确认您要删除整个类型？" ok-text="是" cancel-text="否"
                      @confirm="allEvent.attributeDel.http(attributeType['typeName'],'')">
          <label class="del"><close-outlined /></label>
        </a-popconfirm>
      </div>
      <div class="vList">
        <template v-for="attributeName in attributeType['typeValue']">
          <a-popconfirm title="请确认您要删除当前记录？" ok-text="是" cancel-text="否"
                        @confirm="allEvent.attributeDel.http(attributeType['typeName'],attributeName)">
            <div class="v">{{ attributeName }}</div>
          </a-popconfirm>
        </template>
      </div>
    </div>
  </template>


  <a-drawer
      title="添加一个标签"
      height="130"
      :visible="dataValue.attributeAdd.show"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="top"
      @close="allEvent.attributeAdd.close"
  >

    <attrAdd @goClose="allEvent.attributeAdd.close2" :type_name="dataValue.attributeAdd.typeName"   />
  </a-drawer>

  <a-drawer
      title="修改一个标签的类型名字"
      height="130"
      :visible="dataValue.attributeModify.show"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="top"
      @close="allEvent.attributeModify.close"
  >

    <attrModify @goClose="allEvent.attributeModify.close2" :type_name_old="dataValue.attributeModify.typeName"   />
  </a-drawer>


</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import attrAdd from "@/view/data_center/attribute_xx_add"
import attrModify from "@/view/data_center/attribute_xx_modify"

export default {
  name : "attribute_xx",
  emits : [ 'setMenuKey' ],
  components : { attrAdd, attrModify },
  setup(props, { emit }) {
    emit('setMenuKey', 'datacenter.zdybqsz');

    let dataValue = reactive({

      attributeModify : {
        show : false,
        typeName : ""
      },

      attributeAdd : {
        show : false,
        typeName : ""
      },

      attributeDel : {
        input : {
          typeName : "",
          typeValue : ""
        }
      },

      attributeList : {
        input : {
          typeName : ""
        },
        output : {
          allCount : 0,
          list : []
        }
      }


    });

    let allEvent = {

      attributeModify : {

        open : ( typeName )=>{
          dataValue.attributeModify.show = true;
          dataValue.attributeModify.typeName = typeName;
        },

        close : ()=>{
          dataValue.attributeModify.show = false;
        },

        close2 : ()=>{
          allEvent.attributeList.http();
          dataValue.attributeModify.show = false;
        }

      },

      attributeAdd : {

        open : ( typeName )=>{
          dataValue.attributeAdd.show = true;
          dataValue.attributeAdd.typeName = typeName;
        },

        close : ()=>{
          dataValue.attributeAdd.show = false;
        },

        close2 : ()=>{
          allEvent.attributeList.http();
          dataValue.attributeAdd.show = false;
        }

      },

      attributeList : {

        http : () => {

          proxy.post('/admin/data.center/attribute.list', dataValue.attributeList.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.attributeList.output.allCount = res.data["allCount"];
              dataValue.attributeList.output.list = res.data["list"];
            }
          })

        }

      },

      attributeDel : {

        http : ( typeName, typeValue )=>{

          dataValue.attributeDel.input.typeName = typeName;
          dataValue.attributeDel.input.typeValue = typeValue;

          proxy.post('/admin/data.center/attribute.del', dataValue.attributeDel.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              allEvent.attributeList.http();
            }
          })

        },

      },

      init : () => {
        allEvent.attributeList.http();
      }

    }

    allEvent.init();


    return {
      dataValue, allEvent
    }
  }
}
</script>

<style scoped>
.addTypeName{ margin: 10px 0 10px 40px; }
.attrDiv { margin: 10px 0 20px 30px; clear: both; float: left; }
.attrDiv .t { font-size: 14px; font-weight: bold; margin-bottom: 10px; width: 100%; border-bottom: 1px solid #dddddd;
  padding-bottom: 8px;min-width: 500px;}
.attrDiv .t .tInfo{ cursor: pointer; }
.attrDiv .t .tInfo:hover{ color: red; }
.attrDiv .t .add { margin: 0 10px 0 8px; color: #bbbbbb; }
.attrDiv .t .add :hover{ color: red; cursor: pointer; }
.attrDiv .t .del { margin-left: 8px; color: #bbbbbb; float: right; cursor: pointer; }
.attrDiv .t .del:hover { color: red; }
.attrDiv .vList { margin-left: 20px; }
.attrDiv .vList .v { font-size: 14px; float: left; margin-right: 20px; cursor: pointer; }
.attrDiv .vList .v:hover { color: red; }
</style>